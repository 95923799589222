import React from "react";
import Link from "./Link";

function QuemAjudamos() {
    return(
        <div id="quem-ajudamos">
            <div id="quem-ajudamos-content">
                <h2>Nosso escritório conta com ampla experiência na área do Direito do Trabalho atuando de forma ágil e segura, assessorando-o em todas as etapas do seu caso.</h2>
                <p id="para">Diariamente, ajudamos diversos trabalhadores que: </p>
                <div id="quem-ajudamos-grid">
                    <p>Trabalham ou trabalharam com ou sem carteira assinada</p>
                    <p>Tem hora extra que não recebeu e precisa receber</p>
                    <p>Tem atraso no pagamento de salário, férias e 13º salário</p>
                    <p>Foram demitidos por justa causa</p>
                    <p>Sofreram acidente de trabalho</p>
                    <p>Rescindiram contrato de trabalho</p>
                    <p>Trabalham em ambiente perigoso ou insalubre</p>
                    <p>Já sofreram abusos ou perseguição no ambiente de trabalho</p>
                    <p>Acreditam que sua rescisão está incorreta</p>
                    <p>Acreditam que seus direitos foram violados de alguma forma</p>
                    <p>Seu empregador não depositou o FGTS</p>
                    <p>Não era concedido o intervalo para descanso ou refeições</p>
                    <p>Não recebeu ou não foi concedido férias</p>
                    <p>Trabalhava em mais funções do que deveria</p>
                    <p>Não recebeu participação nos lucros da empresa</p>
                </div>
                <Link></Link>
            </div>
        </div>
    );
}

export default QuemAjudamos;