import './App.css';
import Especialidade from './Especialidade';
import FaleConosco from './FaleConosco';
import Hero from './Hero';
import QuemAjudamos from './QuemAjudamos'
import QuemVaiAtender from './QuemVaiAtender';

function App() {
  return (
    <div className="App">
      <Hero></Hero>
      <QuemAjudamos></QuemAjudamos>
      <QuemVaiAtender></QuemVaiAtender>
      <Especialidade></Especialidade>
      <FaleConosco></FaleConosco>
    </div>
  );
}

export default App;
