import React from "react";
import Link from "./Link";
import img from './dr-img-square.jpg';

function QuemVaiAtender() {
    return(
        <div id="quem-vai-atender"> 
            <div id="quem-vai-atender-content">
                <h2>QUEM VAI TE ATENDER?</h2>
                <div id="quem-vai-atender-grid">
                    <img alt="dr-img" src={img}></img>
                    <p>
                        Dr. Juliano Heimfarth, advogado,
                        inscrito na OAB/RS 100449, 
                        brasileiro, casado e pai de duas filhas.    
                        Graduado pela Universidade de Caxias do Sul, 
                        com especialização em direito trabalhista 
                        pelo Instituto de Desenvolvimento Cultural  
                        de Porto Alegre. Com mais de 10 anos 
                        de experiência, é conhecido e querido por seus 
                        clientes pela sua honestidade, agilidade
                        e facilidade em solucionar os mais 
                        diversos problemas dos trabalhadores 
                        urbanos e rurais.
                    </p>
                </div>
                <Link></Link>
            </div>
        </div>
    );
}

export default QuemVaiAtender;