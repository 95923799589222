import React from 'react';
import logo from './logo-white.png';
import Link from './Link';

function Hero() {
    return(
        <div id='hero'>
            <div id='filter'>
                <div id='hero-content'>
                    <img id="logo" alt='logo' src={logo}></img>
                    <h1>Precisando de um advogado especialista em <br></br>Direito do Trabalho?</h1>
                    <Link></Link>
                    <div id='hero-p'>
                        <p>Atendimento em todo o Vale do Paranhana. </p>
                        <p>Seu processo realizado de forma rápida, simples e eficiente.</p>
                    </div>
                    <div id='hero-grid'>
                        <p>+10<br></br> ANOS DE EXPERIÊNCIA</p>
                        <p>5/5<br></br> NOSSA AVALIAÇÃO NO GOOGLE</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Hero;