import React from "react";
import logo from './logo-black.png';

function FaleConosco() {
    return(
        <div id="fale-conosco">
            <div id="fale-conosco-content"></div>
            <h2>FALE CONOSCO!</h2>
            <div id="fale-conosco-grid">
                <p className="a1"><span className="bold">WhatsApp</span><br></br>(51) 9 9690-5500</p>
                <p className="a2"><span className="bold">Email</span><br></br>atendimento.jhadvocacia@gmail.com</p>
                <img className="a3" alt="logo" src={logo}></img>
                <iframe className="a4" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13869.815634180433!2d-50.781428!3d-29.6486001!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x951919134ce40caf%3A0xbf2673472e996e1b!2sJH%20Advocacia!5e0!3m2!1spt-BR!2sbr!4v1696278213922!5m2!1spt-BR!2sbr"></iframe>
            </div>
        </div>
    );
}

export default FaleConosco;