import React from "react";
import Link from './Link';

function Especialidade() {
    return(
        <div id="especialidade">
            <div id="especialidade-content">
                <div id="especialidade-grid">
                    <h2 className="item1">EQUIPE ESPECIALIZADA</h2>
                    <p className="item2">Somos especialistas em Direito Trabalhista. Isso significa que dedicamos todas nossas energias para entregar solução e tranquilidade àqueles que precisam do nosso apoio.</p>

                    <h2 className="item3">ATENDIMENTO PERSONALIZADO</h2>
                    <p className="item4">Você não é – e nunca será – apenas mais um cliente. Cada história importa e nós sentimos na pele a dor de nossos clientes. Receba atendimento individualizado e humanizado.</p>

                    <h2 className="item5">ACOMPANHAMENTO INTEGRAL</h2>
                    <p className="item6">Nós não deixamos você na mão. Vamos até o final para te trazer a melhor solução, independentemente de quão longo seja o caminho.</p>
                </div>
                <Link></Link>
            </div>
        </div>
    );
}

export default Especialidade;