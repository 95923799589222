import React from "react";

function Link() {
    var dataLayer = window.dataLayer = window.dataLayer || [];

        function gtag(){
            dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', 'AW-336377875');
            
        function gtag_report_conversion(url) {
            var callback = function () {
              if (typeof(url) != 'undefined') {
                window.location = url;
              }
            };
            gtag('event', 'conversion', {
                'send_to': 'AW-336377875/YGbvCODBn-kYEJPwsqAB',
                'event_callback': callback
            });
            return false;
          }
          
    return(
        <div className="link-div">
          <a href='https://api.whatsapp.com/send?phone=5551996905500&text=Ol%C3%A1!%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20meu%20caso,%20por%20favor!' className="link" onClick={() => gtag_report_conversion('https://api.whatsapp.com/send?phone=5551996905500&text=Ol%C3%A1!%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20meu%20caso,%20por%20favor!')}>CLIQUE AQUI E FALE COM UM ADVOGADO TRABALHISTA SOBRE SEU CASO</a>
        </div>
    );
}

export default Link;